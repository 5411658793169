/* Component Dependencies */
var mainNavLinkTemplate = require('templates/mainNavLink.hbs');
var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');

PubSub.register(AriesComponent.extend({
  type: 'mainNavLink',
  template: {
    'mainNavLink': mainNavLinkTemplate
  },
	bindEvents: function($) {
    'use strict';
    // code specific to signInOverlay moadal here
    this.$ = $;
    $(document).trigger('GLOBAL_ELEMENTS_REINIT.mi-header');
  }
}));
